import { useEffect, useRef, useState } from 'react';
import { TextButton } from './text-button';

type CopyTextButtonProps = {
  value?: string | null;
  children: string;
};

export const CopyTextButton = ({ value, children }: CopyTextButtonProps) => {
  const timeout = useRef<ReturnType<typeof window.setTimeout> | null>(null);
  const [buttonLabel, setButtonLabel] = useState(children);

  useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, []);

  const handleCopy = async () => {
    if (!value) {
      return;
    }

    await navigator.clipboard.writeText(value);

    setButtonLabel('Copied!');
    timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setButtonLabel(children);
    }, 500);
  };

  return <TextButton onPress={handleCopy}>{buttonLabel}</TextButton>;
};
